import { BillingCycle } from 'api/types/BillingSummary.types';

export const invoiceDataActions = {
  clear: 'clear',
  issueMode: 'issueMode',
  issueModeClose: 'issueModeClose',
} as const;
export type InvoiceDataActionsType = typeof invoiceDataActions[keyof typeof invoiceDataActions];

export type InvoiceData = {
  checked: boolean;
  invoiceId: string;
  billingCycle: BillingCycle;
  projectName: string;
  projectId: string;
  currency: string;
  amount: number;
};

export type InvoiceDataParams = {
  invoiceId: string;
  billingCycleId: string;
  projectId: string;
};

export type IssueInvoiceParams = {
  invoiceNumber: string;
  invoiceData: InvoiceDataParams[];
  source: 'PM' | 'FINANCE';
};
