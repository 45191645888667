import { MouseEvent, useEffect, useState } from 'react';

import { Box, Stack, Breadcrumbs } from '@mui/material';
import { Button, Grid, MenuList, Tooltip } from 'lux/components';
import { CalendarIcon, CloseIcon, DeleteIcon, MoreIcon, ReplaceIcon, SendIcon } from 'lux/icons';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { getNumberOfCalendarDaysBetweenDates, isCurrentDate, isFutureDate, isPastDate } from 'utils/dateUtils';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { RequestNeed } from 'app/need/request/RequestNeed';
import { useDialog } from 'hooks/useDialog/useDialog';
import { useUndoSnackbar } from 'hooks/useUndoSnackbar/useUndoSnackbar';
import { useCancelProjectExtension } from 'hooks/useCancelProjectExtension/useCancelProjectExtension';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { canRequestNeed } from 'utils/projectUtils';
import { ConvertLeadModal } from '../convertLeadModal/ConvertLeadModal';
import { CloseProjectModal } from '../closeProjectModal/CloseProjectModal';
import { projectSetupSteps } from '../projectSetupSteps';
import { AppRoute } from 'routing/AppRoute.enum';
import { SquareIconButton } from 'ui/squareIconButton/SquareIconButton';
import { Translation } from 'ui/translation/Translation';
import { ArchiveLeadModal } from '../archiveLeadModal/ArchiveLeadModal';
import { ReopenProjectModal } from '../reopenProjectModal/ReopenProjectModal';
import { PlanProjectExtensionModal } from '../planProjectExtensionModal/PlanProjectExtensionModal';
import { checkIsProjectOngoingOrClosing } from 'utils/checkers/checkIsProjectOngoingOrClosing';
import { projectsKeys } from 'utils/queryKeys';
import { ProjectSource } from '../../../api/actions/getProject/getProject.types';
import { DisconnectFromCRMModal } from '../disconnectFromCRMModal/DisconnectFromCRMModal';

import { ProjectName } from './projectName/ProjectName';
import * as styles from './ProjectHeader.styles';
import { ProjectBanners } from './projectBanners/ProjectBanners';

const stagesWhenShowingMoreIcon: string[] = [
  ProjectStage.ongoing,
  ProjectStage.lead,
  ProjectStage.closed,
  ProjectStage.closing,
];

const MAX_DAYS_TO_END_DATE = 30;

export const ProjectHeader = () => {
  const queryClient = useQueryClient();
  const { projectDetails, allowProjectEdition, isLeadFromHubSpot } = useProjectDetails();
  const {
    id,
    name,
    stage,
    endDate,
    startDate,
    plannedExtension,
    client: { id: clientId, name: clientName },
    projectSource,
  } = projectDetails;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { formatMessage } = useLocale();
  const { isOpen: isRequestModalOpen, setOpen: setRequestModalOpen, setClose: setRequestModalClose } = useDialog();
  const {
    isOpen: isConvertLeadModalOpen,
    setOpen: setConvertLeadModalOpen,
    setClose: setConvertLeadModalClose,
  } = useDialog();

  const {
    isOpen: isCloseProjectModalOpen,
    setOpen: setCloseProjectModalOpen,
    setClose: setCloseProjectModalClose,
  } = useDialog();
  const {
    isOpen: isArchiveLeadModalOpen,
    setOpen: setArchiveLeadModalOpen,
    setClose: setArchiveLeadModalClose,
  } = useDialog();
  const {
    isOpen: isReopenProjectModalOpen,
    setOpen: setReopenProjectModalOpen,
    setClose: setReopenProjectModalClose,
  } = useDialog();
  const {
    isOpen: isPlanProjectExtensionModalOpen,
    setOpen: setPlanProjectExtensionModalOpen,
    setClose: setPlanProjectExtensionModalClose,
  } = useDialog();

  const {
    isOpen: isDisconnectFromCRMModalOpen,
    setOpen: setDisconnectFromCRMModalOpen,
    setClose: setDisconnectFromCRMModalClose,
  } = useDialog();

  const steps = projectSetupSteps(projectDetails);
  const allStepsCompleted = steps.every((step) => !!step.completed);
  const enableConvertToProject = stage === ProjectStage.lead && allStepsCompleted;
  const isFromHubspot = projectSource === ProjectSource.hubspot;
  const isExtensionPossibleForCurrentStage = checkIsProjectOngoingOrClosing(stage as ProjectStage);
  const isProjectQueryLoading = !!queryClient.isFetching(projectsKeys.singleProject(id));
  const canStartProject =
    isCurrentDate(new Date(projectDetails.startDate)) || isFutureDate(new Date(projectDetails.startDate));

  const endsWithinThreshold =
    !!endDate && getNumberOfCalendarDaysBetweenDates(new Date(endDate), new Date()) < MAX_DAYS_TO_END_DATE;
  const openPlanExtensionBanner =
    isExtensionPossibleForCurrentStage && endsWithinThreshold && !isPastDate(new Date(endDate)) && !plannedExtension;
  const openExtensionConfirmationBanner = plannedExtension && isExtensionPossibleForCurrentStage;

  const { mutate: cancelProjectExtension, isLoading: isCancelProjectExtensionLoading } = useCancelProjectExtension(id);

  const handleCancelProjectExtension = useUndoSnackbar(
    formatMessage({ id: AppMessages['snackbar.cancelProjectExtension.success'] }),
    () => cancelProjectExtension({}, {}),
  );

  const getTile = () => {
    if (!enableConvertToProject) {
      return formatMessage({ id: AppMessages['projectDetails.header.button.convertLead.tooltip'] });
    }

    return '';
  };

  const [isPlanExtensionBannerOpen, setIsPlanExtensionBannerOpen] = useState<boolean>(openPlanExtensionBanner);
  const [isCancelExtensionBannerOpen, setIsCancelExtensionBannerOpen] = useState<boolean>(
    openExtensionConfirmationBanner,
  );

  useEffect(() => {
    setIsPlanExtensionBannerOpen(openPlanExtensionBanner);
    setIsCancelExtensionBannerOpen(openExtensionConfirmationBanner);
  }, [openPlanExtensionBanner, openExtensionConfirmationBanner]);

  const handleConfirmProjectExtension = () => {
    setAnchorEl(null);
    setPlanProjectExtensionModalOpen();
    setIsPlanExtensionBannerOpen(false);
  };

  const isPlanExtensionMenuButton = isExtensionPossibleForCurrentStage && !plannedExtension;
  const isProjectOngoing = stage === ProjectStage.ongoing;
  const isProjectLead = stage === ProjectStage.lead;
  const isPossibleProjectReopen =
    stage === ProjectStage.closed && process.env.REACT_APP_REOPEN_FEATURE_ENABLED === 'true';
  const isAnyMenuButton =
    isPlanExtensionMenuButton || plannedExtension || isProjectOngoing || isProjectLead || isPossibleProjectReopen;

  return (
    <>
      <Box sx={styles.headerWrapper}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between" gutter={0}>
          <Grid item xs={12}>
            <Breadcrumbs>
              <Button
                component={Link}
                to={`${AppRoute.clients}/${clientId}`}
                variant="text"
                size="small"
                sx={styles.button}
              >
                {clientName}
              </Button>
              <Button component={Link} to={AppRoute.projects} variant="text" size="small" sx={styles.button} disabled>
                {name}
              </Button>
            </Breadcrumbs>
          </Grid>
          <Grid item xs>
            <ProjectName
              id={id}
              name={name}
              stage={stage}
              clientId={clientId}
              isLeadFromHubSpot={isLeadFromHubSpot}
              allowEdition={allowProjectEdition}
            />
          </Grid>
          <Grid item xs="auto">
            <Stack spacing={3} direction="row">
              {isProjectLead && (
                <>
                  <Tooltip title={getTile()} placement="left" arrow>
                    <Box>
                      <Button
                        disabled={!enableConvertToProject}
                        onClick={setConvertLeadModalOpen}
                        startIcon={<ReplaceIcon />}
                        variant="outlined"
                      >
                        <Translation id="projectDetails.header.button.convertLead" />
                      </Button>
                    </Box>
                  </Tooltip>
                  <Box>
                    {isFromHubspot && (
                      <Button onClick={setDisconnectFromCRMModalOpen} variant="outlined">
                        <Translation id="projectDetails.header.button.disconnectCRM" />
                      </Button>
                    )}
                  </Box>
                </>
              )}
              {stage === ProjectStage.closing && (
                <Button variant="contained" onClick={setCloseProjectModalOpen} color="error" startIcon={<CloseIcon />}>
                  <Translation id="projectDetails.header.button.closeProject" />
                </Button>
              )}
              {canRequestNeed(allowProjectEdition, endDate) && (
                <Button
                  onClick={setRequestModalOpen}
                  size="medium"
                  variant="contained"
                  color="primary"
                  startIcon={<SendIcon />}
                  data-cy="project-details-header-request-btn"
                >
                  <Translation id="projectDetails.header.button.requestNeed" />
                </Button>
              )}
              {stagesWhenShowingMoreIcon.includes(stage) && isAnyMenuButton && (
                <SquareIconButton color="primary" onClick={handleMenuOpen} size="medium" variant="outlinedGray">
                  <MoreIcon />
                </SquareIconButton>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <ProjectBanners
        isCancelExtensionBannerOpen={isCancelExtensionBannerOpen}
        isPlanExtensionBannerOpen={isPlanExtensionBannerOpen}
        onConfirmProjectExtension={handleConfirmProjectExtension}
        onCloseProjectExtension={() => setIsPlanExtensionBannerOpen(false)}
        onCancelProjectExtension={() => {
          handleCancelProjectExtension();
          setIsCancelExtensionBannerOpen(false);
        }}
      />
      <MenuList
        sx={styles.dropdownMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        {isPlanExtensionMenuButton && (
          <Tooltip
            title={
              !endDate || isPastDate(new Date(endDate)) ? (
                <Translation id="projectDetails.button.planProjectExtension.tooltip" />
              ) : null
            }
            arrow
          >
            <Box>
              <MenuList.MenuItem
                onClick={handleConfirmProjectExtension}
                icon={<CalendarIcon />}
                disabled={!endDate || isPastDate(new Date(endDate))}
              >
                <Translation id="projectDetails.button.planProjectExtension" />
              </MenuList.MenuItem>
            </Box>
          </Tooltip>
        )}
        {plannedExtension && (
          <MenuList.MenuItem
            onClick={() => {
              setAnchorEl(null);
              handleCancelProjectExtension();
            }}
            icon={<CalendarIcon />}
            disabled={isCancelProjectExtensionLoading || isProjectQueryLoading}
          >
            <Translation id="projectDetails.button.cancelProjectExtension" />
          </MenuList.MenuItem>
        )}
        {isProjectOngoing && (
          <Tooltip
            title={canStartProject ? <Translation id="projectDetails.button.closeProject.tooltip" /> : null}
            arrow
          >
            <Box>
              <MenuList.MenuItem
                sx={styles.menuListIcon}
                onClick={() => {
                  setAnchorEl(null);
                  setCloseProjectModalOpen();
                }}
                icon={<DeleteIcon />}
                disabled={canStartProject}
              >
                <Translation id="projectDetails.button.closeProject" />
              </MenuList.MenuItem>
            </Box>
          </Tooltip>
        )}
        {isProjectLead && (
          <MenuList.MenuItem
            onClick={() => {
              setAnchorEl(null);
              setArchiveLeadModalOpen();
            }}
            icon={<DeleteIcon />}
          >
            <Translation id="projectDetails.button.archiveLead" />
          </MenuList.MenuItem>
        )}
        {isPossibleProjectReopen && (
          <MenuList.MenuItem
            onClick={() => {
              setAnchorEl(null);
              setReopenProjectModalOpen();
            }}
          >
            <Translation id="projectDetails.button.reopenProject" />
          </MenuList.MenuItem>
        )}
      </MenuList>
      <RequestNeed projectId={id} open={isRequestModalOpen} onClose={setRequestModalClose} />
      <ConvertLeadModal
        projectName={name}
        projectId={id}
        startDate={startDate}
        open={isConvertLeadModalOpen}
        onClose={setConvertLeadModalClose}
      />
      <CloseProjectModal
        projectId={id}
        projectStartDate={startDate}
        projectEndDate={endDate}
        projectName={name}
        open={isCloseProjectModalOpen}
        onClose={setCloseProjectModalClose}
      />
      <ArchiveLeadModal
        projectName={name}
        projectId={id}
        open={isArchiveLeadModalOpen}
        onClose={setArchiveLeadModalClose}
      />
      <ReopenProjectModal
        projectName={name}
        projectId={id}
        open={isReopenProjectModalOpen}
        onClose={setReopenProjectModalClose}
      />
      <PlanProjectExtensionModal
        projectId={id}
        open={isPlanProjectExtensionModalOpen}
        onClose={setPlanProjectExtensionModalClose}
        projectEndDate={endDate}
      />
      <DisconnectFromCRMModal
        projectId={id}
        open={isDisconnectFromCRMModalOpen}
        onClose={setDisconnectFromCRMModalClose}
      />
    </>
  );
};
