import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { issueInvoice } from 'api/actions/issueInvoice/issueInvoice';
import { InvoiceData, invoiceDataActions, InvoiceDataActionsType } from 'api/actions/issueInvoice/issueInvoice.types';
import { confirmInvoiceErrorDefaultState } from 'context/projectBillingSummary/projectBillingSummaryDataContextController/ProjectBillingSummaryDataContextController';
import { UseConfirmInvoiceConflictError } from 'hooks/useConfirmInvoice/useConfirmInvoice.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { AppMessages } from 'i18n/messages';
import { formatDate } from 'utils/dateUtils';
import { getInvoicesQueryKey, projectsKeys } from 'utils/queryKeys';

import { IssueInvoiceErrorEnum } from './useIssueInvoice.types';

export const useIssueInvoice = (
  invoiceData: InvoiceData[],
  invoiceDataAction?: (action: InvoiceDataActionsType) => void,
) => {
  const queryClient = useQueryClient();

  const { groupBy, selectedDate, setConfirmInvoiceError } = useProjectBillingSummaryData();
  const { formatMessage } = useLocale();

  return useMutation(['issueInvoice'], issueInvoice(), {
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: AppMessages['projectDetails.invoice.actions.add.success'] }));
      invoiceData.forEach(({ projectId }) => {
        queryClient.invalidateQueries(getInvoicesQueryKey({ projectId }));
        queryClient.refetchQueries(projectsKeys.projectBillingSummary(projectId, formatDate(selectedDate), groupBy)); // To fix: LUX-2390.
      });
      setConfirmInvoiceError(confirmInvoiceErrorDefaultState);
      sessionStorage.removeItem('confirmInvoiceError');
      invoiceDataAction?.(invoiceDataActions.clear);
      invoiceDataAction?.(invoiceDataActions.issueModeClose);
    },
    onError: (err) => {
      const { stack } = err.response?.data as UseConfirmInvoiceConflictError;

      const setConfirmInvoiceErrorHandler = (errorType: string, message: string) => {
        setConfirmInvoiceError({
          message,
          errorType,
        });
      };

      if (stack.includes(IssueInvoiceErrorEnum.invoiceNotFoundError)) {
        setConfirmInvoiceErrorHandler(
          IssueInvoiceErrorEnum.invoiceNotFoundError,
          'projectDetails.invoice.actions.add.error.number.mismatch',
        );
      }

      if (stack.includes(IssueInvoiceErrorEnum.invoiceTotalAmountMismatchError)) {
        setConfirmInvoiceErrorHandler(
          IssueInvoiceErrorEnum.invoiceTotalAmountMismatchError,
          'projectDetails.invoice.actions.add.error.amount.mismatch',
        );
      }

      if (stack.includes(IssueInvoiceErrorEnum.invoiceCurrencyMismatchError)) {
        setConfirmInvoiceErrorHandler(
          IssueInvoiceErrorEnum.invoiceCurrencyMismatchError,
          'projectDetails.invoice.actions.add.error.currency.mismatch',
        );
      }

      if (stack.includes(IssueInvoiceErrorEnum.invoiceExistError)) {
        setConfirmInvoiceErrorHandler(
          IssueInvoiceErrorEnum.invoiceExistError,
          'projectDetails.invoice.actions.add.error.currency.exist',
        );
      }
    },
  });
};
