import { useState } from 'react';

import { Button, DateTimePicker, TextField } from 'lux/components';
import { Stack, Box } from '@mui/system';
import { CheckIcon, CloseIcon } from 'lux/icons';

import { useSendInvoice } from 'hooks/useSendInvoice/useSendInvoice';
import { useInvoiceCorrection } from 'hooks/useInvoiceCorrection/useInvoiceCorrection';
import { usePayInvoice } from 'hooks/usePayInvoice/usePayInvoice';
import { Translation } from 'ui/translation/Translation';
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';
import { InvoiceStatusEnum } from 'api/types/BillingSummary.types';
import { DEFAULT_DATE_FORMAT, formatDate, isPastOrPresentDate } from 'utils/dateUtils';
import { canEditInvoice } from 'utils/canEditInvoice';
import { ButtonWithPopover } from 'ui/buttonWithPopover/ButtonWithPopover';
import { PopoverHeading } from 'ui/popoverHeading/PopoverHeading';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useClient } from 'hooks/useClient/useClient';

import { InvoiceActionsProps } from './InvoiceActions.types';
import * as styles from './InvoiceActions.styles';

export const InvoiceActions = ({ invoice }: InvoiceActionsProps) => {
  const { clientDetails } = useClient();
  const [paymentDate, setPaymentDate] = useState<Date | null>(new Date());
  const [paymentDatePopoverOpen, setPaymentDatePopoverOpen] = useState(false);
  const { isLoading: isSendingInvoice, mutate: sendInvoice } = useSendInvoice(invoice.id);
  const { isLoading: requestingInvoiceCorrection, mutate: requestInvoiceCorrection } = useInvoiceCorrection(
    invoice.id,
    clientDetails?.client.id,
  );
  const { isLoading: isMarkingInvoicePaid, mutate: markInvoicePaid } = usePayInvoice(invoice.id);
  const { data: user } = useCurrentUser();
  const { t } = useLocale();
  const isButtonDisabled = !canEditInvoice(user);

  const handleMarkInvoicePaid = async () => {
    if (paymentDate) {
      markInvoicePaid({
        paymentDate: formatDate(paymentDate),
      });
      closePaymentDatePopover();
    }
  };

  const closePaymentDatePopover = () => {
    setPaymentDatePopoverOpen(false);
  };

  const isPaymentDateValid = !!paymentDate && isPastOrPresentDate(paymentDate);

  if (invoice.status === InvoiceStatusEnum.invoice_issued) {
    return (
      <>
        <Button size="small" loadingPosition="center" loading={isSendingInvoice} onClick={sendInvoice}>
          <Translation id="projectDetails.invoice.actions.sendInvoice" />
        </Button>
        <Button
          size="small"
          variant="outlined"
          loadingPosition="center"
          loading={requestingInvoiceCorrection}
          onClick={requestInvoiceCorrection}
          disabled={isButtonDisabled}
        >
          <Translation id="projectDetails.invoice.actions.toCorrect" />
        </Button>
      </>
    );
  }

  if (invoice.status === InvoiceStatusEnum.sent) {
    return (
      <ButtonWithPopover
        open={paymentDatePopoverOpen}
        label={t('projectDetails.invoice.actions.selectPaymentDate')}
        popoverSpacing={3}
        activeOnOpen
        ButtonProps={{ onClick: () => setPaymentDatePopoverOpen(true), sx: {} }}
        onClose={closePaymentDatePopover}
      >
        <Stack gap={2}>
          <PopoverHeading title={t('projectDetails.invoice.actions.selectPaymentDate')} />
          <Box sx={styles.datePickerRow}>
            <DateTimePicker
              inputFormat={DEFAULT_DATE_FORMAT}
              maxDate={new Date()}
              value={paymentDate}
              views={['day']}
              onChange={(date) => setPaymentDate(date)}
              renderInput={(params) => <TextField {...params} size="small" autoFocus />}
            />
            <Box sx={styles.popoverActions}>
              <Button
                iconOnly
                size="small"
                onClick={handleMarkInvoicePaid}
                disabled={!isPaymentDateValid || isMarkingInvoicePaid}
                loading={isMarkingInvoicePaid}
                loadingPosition="center"
              >
                <CheckIcon />
              </Button>
              <Button
                iconOnly
                size="small"
                variant="outlined"
                onClick={closePaymentDatePopover || isMarkingInvoicePaid}
              >
                <CloseIcon />
              </Button>
            </Box>
          </Box>
        </Stack>
      </ButtonWithPopover>
    );
  }

  return null;
};
