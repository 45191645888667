import { Table } from 'lux/components';
import { uniqueId } from 'lodash';

import { Translation } from 'ui/translation/Translation';

import * as styles from './ProjectInvoices.styles';
import { InvoiceRowSkeleton } from './invoiceRow/InvoiceRow.skeleton';
import { LoadMoreInvoicesSkeleton } from './loadMoreInvoices/LoadMoreInvoices.skeleton';

export const ProjectInvoicesSkeleton = () => (
  <Table.Table sx={styles.table}>
    <Table.Head>
      <Table.Row>
        <Table.Cell size="small">
          <Translation id="projectDetails.invoices.invoiceNumber" />
        </Table.Cell>
        <Table.Cell size="small">
          <Translation id="projectDetails.invoices.status" />
        </Table.Cell>
        <Table.Cell size="small">
          <Translation id="projectDetails.invoices.workStatement" />
        </Table.Cell>
        <Table.Cell size="small">
          <Translation id="projectDetails.invoices.billingCycle" />
        </Table.Cell>
        <Table.Cell size="small">
          <Translation id="projectDetails.invoices.invoiceAmount" />
        </Table.Cell>
        <Table.Cell size="small">
          <Translation id="projectDetails.invoices.action" />
        </Table.Cell>
        <Table.Cell size="small" />
      </Table.Row>
    </Table.Head>
    <Table.Body>
      {Array(5)
        .fill(null)
        .map(() => (
          <InvoiceRowSkeleton key={uniqueId()} />
        ))}
      <LoadMoreInvoicesSkeleton />
    </Table.Body>
  </Table.Table>
);
