import { Assignment } from 'api/actions/getPersonAssignments/getPersonAssignments.types';
import { convertRateToDecimal } from 'utils/convertRate';
import { GetAssignmentsResponse } from 'api/actions/getAssignments/getAssignments.types';
import { GetRejectionsResponse, Rejection } from 'api/actions/getRejections/getRejections.types';
import { getNumberOfCalendarDaysBetweenDates, isDateBefore, parseDate, subtractDaysFromDate } from 'utils/dateUtils';
import { EmployeeAssignments } from 'context/projectDetails/projectDetailsContext/ProjectDetailsContext.types';

import { AssignmentsGroupedByDate } from './Assignments.types';

type Rejections = Record<string, Rejection> | undefined;

export const groupAssignmentsByEndDate = (assignments: Assignment[]): AssignmentsGroupedByDate =>
  Object.entries(
    assignments.reduce<{ [key: string]: Assignment[] }>((assignments, assignment) => {
      const endDate = assignment.endDate as string;

      if (!assignments[endDate]) {
        assignments[endDate] = [];
      }

      assignments[endDate].push(assignment);

      return assignments;
    }, {}),
  );

export const mapAssignmentData = (data?: GetAssignmentsResponse[], rejectionData?: GetRejectionsResponse[]) => {
  let linkedRejection: Rejections;

  if (rejectionData) {
    const rejectionsList = rejectionData.flatMap((page) => page.rejections);

    linkedRejection = rejectionsList.reduce<{ [key: string]: Rejection }>((rejections, rejection) => {
      const assignmentId = rejection.assignment?.id;

      if (!assignmentId) {
        return rejections;
      }

      rejections[assignmentId] = rejection;

      return rejections;
    }, {});
  }

  const minDateForRemoveAssignment = data?.flatMap((page) => page.minDateForRemoveAssignment)?.[0];

  return data
    ?.flatMap((page) => page.assignments)
    .map((assignment) => ({
      ...assignment,
      rateModifier: assignment.rateModifier && convertRateToDecimal(assignment.rateModifier),
      effectiveRateModifier: assignment.effectiveRateModifier && convertRateToDecimal(assignment.effectiveRateModifier),
      linkedRejection: linkedRejection?.[assignment.id],
      minDateForRemoveAssignment,
      person: {
        ...assignment.person,
        rate: assignment.person.rate && convertRateToDecimal(assignment.person.rate),
      },
    }));
};

export const getMaxAvailableAssignDate = (
  employeeAssignments: EmployeeAssignments[],
  startDate: Date | null,
  employeeId: number,
  employedTo: string | null,
): Date | undefined => {
  if (!startDate) {
    return;
  }

  const personAssignments = employeeAssignments.find((assigment) => assigment.employeeId === employeeId)?.assignments;
  const mappedStartDates = personAssignments?.map((assignment) => ({
    startDate: assignment.startDate,
    differenceInDays: getNumberOfCalendarDaysBetweenDates(parseDate(assignment.startDate), startDate),
  }));
  const futureStartDates = mappedStartDates?.filter(({ differenceInDays }) => differenceInDays > 0);
  const nearestFutureDate = futureStartDates?.reduce(
    (acc, curr) => (curr.differenceInDays < acc.differenceInDays ? curr : acc),
    futureStartDates[0],
  )?.startDate;

  const parsedEmployedTo = employedTo ? parseDate(employedTo) : undefined;

  if (nearestFutureDate) {
    const parsedFutureDate = parseDate(nearestFutureDate);

    if (parsedEmployedTo && isDateBefore(parsedEmployedTo, parsedFutureDate)) {
      return parsedEmployedTo;
    }

    return subtractDaysFromDate(parsedFutureDate, 1);
  }

  return parsedEmployedTo;
};
