import { useContext } from 'react';

import { ProjectsListConfigContext } from 'context/projectListConfig/projectsListConfigContext/ProjectsListConfigContext';

export const useProjectsListConfig = () => {
  const context = useContext(ProjectsListConfigContext);

  if (context === undefined) {
    throw new Error('ProjectsListConfigContext must be within ProjectsListConfigContextController');
  }

  return context;
};
