import { useEffect, useState } from 'react';

import { Box, Divider } from '@mui/material';
import { Typography } from 'lux/components';

import { InvoiceData, invoiceDataActions, InvoiceDataActionsType } from 'api/actions/issueInvoice/issueInvoice.types';
import { BillingSummaryGroupBy } from 'api/types/BillingSummary.types';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { ProjectBillingSummaryContextController } from 'context/projectBillingSummary/projectBillingSummaryContextController/ProjectBillingSummaryContextController';
import { ProjectBillingSummaryDataContextController } from 'context/projectBillingSummary/projectBillingSummaryDataContextController/ProjectBillingSummaryDataContextController';
import { ProjectDetailsContextController } from 'context/projectDetails/projectDetailsContextController/ProjectDetailsContextController';
import { ProjectDiscrepancyContextController } from 'context/projectDiscrepancy/projectDiscrepancyContextController/ProjectDiscrepancyContextController';
import { useClient } from 'hooks/useClient/useClient';
import { useFormatCurrency } from 'hooks/useFormatCurrency/useFormatCurrency';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { CardWrapper } from 'ui/cardWrapper/CardWrapper';
import { EmptyState } from 'ui/emptyState/EmptyState';
import { Loader } from 'ui/loader/Loader';

import * as styles from './ClientBillingSummary.styles';
import { ClientBillingSummaryAccordionItem } from './clientBillingSummaryAccordionItem/ClientBillingSummaryAccordionItem';
import { TotalAmountOfProjects } from './clientBillingSummaryAccordionItem/ClientBillingSummaryAccordionItem.types';

export const ClientBillingSummary = () => {
  const [monthForBI, setMonthForBI] = useState(new Date());
  const [issueInvoiceMode, setIssueInvoiceMode] = useState(false);
  const [groupByAllBI, setGroupByAllBI] = useState(BillingSummaryGroupBy.MEMBERS);
  const [totalAmountOfBI, setTotalAmountOfBI] = useState<TotalAmountOfProjects[]>([]);
  const [loading, setIsLoading] = useState<Record<number, boolean> | null>(null);
  const [invoiceData, setInvoiceData] = useState<InvoiceData[]>([]);
  const totalAmountDivisor = 100;

  const { clientDetails, isLoading: isClientLoading } = useClient();
  const formatCurrency = useFormatCurrency(clientDetails?.client.projects[0]?.currency ?? 'USD');
  const { formatMessage } = useLocale();
  const { t } = useLocale();

  const handleLoading = (id: number, loading: boolean) => {
    setIsLoading((prev) => ({ ...prev, [id]: loading }));
  };

  useEffect(() => {
    setTotalAmountOfBI([]);
  }, [monthForBI]);

  if (isClientLoading) {
    return (
      <CardWrapper>
        <Loader />;
      </CardWrapper>
    );
  }

  const handleProjectCheckboxChange = (data: InvoiceData) => {
    setInvoiceData((prevInvoiceData) => {
      if (data.checked) {
        return [
          ...prevInvoiceData,
          {
            ...data,
          },
        ];
      }

      const filteredInvoiceData = prevInvoiceData.filter(
        (invoiceData) => invoiceData.projectId !== data.billingCycle.projectId,
      );
      if (!filteredInvoiceData.length) {
        setIssueInvoiceMode(false);
      }
      return filteredInvoiceData;
    });
  };

  const invoiceDataAction = (action: InvoiceDataActionsType) => {
    if (action === invoiceDataActions.clear) {
      setInvoiceData([]);
      setIssueInvoiceMode(false);
      return;
    }
    if (action === invoiceDataActions.issueModeClose) {
      setIssueInvoiceMode(false);
      return;
    }
    if (action === invoiceDataActions.issueMode) {
      setIssueInvoiceMode(true);
      return;
    }
  };

  const activeProjects = clientDetails
    ? clientDetails.client.projects.filter(({ stage }) => [ProjectStage.ongoing, ProjectStage.closing].includes(stage))
    : [];

  const getBI = issueInvoiceMode
    ? totalAmountOfBI.filter(({ projectId }) => invoiceData.some((data) => data.projectId === projectId))
    : totalAmountOfBI;

  const getTotalAmount = formatCurrency(
    getBI.reduce((acc, currentValue) => acc + currentValue.amount, 0) / totalAmountDivisor,
  );

  const projects = issueInvoiceMode
    ? activeProjects.filter(({ id }) => invoiceData.some(({ projectId }) => id === projectId))
    : activeProjects;

  const isLoading = Object.values(loading ?? []).some((el) => el);

  return (
    <CardWrapper>
      {activeProjects.length ? (
        <>
          {projects.map((project, index) => (
            <ProjectDetailsContextController key={project.id} projectId={project.id}>
              <ProjectDiscrepancyContextController projectId={project.id}>
                <ProjectBillingSummaryContextController>
                  <ProjectBillingSummaryDataContextController>
                    <ClientBillingSummaryAccordionItem
                      bINumber={index}
                      monthForBI={monthForBI}
                      setMonthForBI={setMonthForBI}
                      groupByAllBI={groupByAllBI}
                      setGroupByAllBI={setGroupByAllBI}
                      setTotalAmountOfBI={setTotalAmountOfBI}
                      setLoading={handleLoading}
                      totalAmountOfBI={totalAmountOfBI}
                      onCheckboxChange={handleProjectCheckboxChange}
                      invoiceDataAction={invoiceDataAction}
                      invoiceData={invoiceData}
                      issueInvoiceMode={issueInvoiceMode}
                    />
                  </ProjectBillingSummaryDataContextController>
                </ProjectBillingSummaryContextController>
              </ProjectDiscrepancyContextController>
            </ProjectDetailsContextController>
          ))}
          {!isLoading && totalAmountOfBI.length !== 0 && <Divider variant="middle" />}
          {totalAmountOfBI.length > 0 && (
            <Box sx={styles.totalClientMoneyAmountContainer}>
              <Typography variant="h6" sx={styles.totalClientMoneyAmount}>
                {formatMessage(
                  { id: AppMessages['client.billing.information.total.amount'] },
                  {
                    client: clientDetails?.client.name,
                  },
                )}
              </Typography>
              <Typography variant="h6" sx={styles.amount}>
                {getTotalAmount}
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <EmptyState
          illustration="emptyBillingInfo"
          title={t('client.billing.information.not.found.title')}
          message={''}
        />
      )}

      {!isLoading && totalAmountOfBI.length === 0 && (
        <Box mt={10}>
          <EmptyState
            illustration="emptyBillingInfo"
            title={t('client.billing.information.not.found.title')}
            message={''}
          />
        </Box>
      )}
    </CardWrapper>
  );
};
