import { useCallback } from 'react';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useTitle } from 'hooks/useTitle/useTitle';
import { useClient } from 'hooks/useClient/useClient';
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage';
import { AppMessages } from 'i18n/messages';

import { Sidebar } from './sidebar/Sidebar';
import { ClientHeader } from './clientHeader/ClientHeader';
import * as styles from './Client.styles';

export const Client = () => {
  const { formatMessage } = useLocale();
  const { clientDetails, isLoading: isClientLoading } = useClient();

  const [sidebarPinned, setSidebarPinned] = useLocalStorage('sidebarPinned', false);

  useTitle(formatMessage({ id: AppMessages['title.projectDetails'] }, { projectName: `Test` }));

  const togglePin = useCallback(() => {
    setSidebarPinned(!sidebarPinned);
  }, [sidebarPinned]);

  return (
    <Box sx={styles.clientViewWrapper(sidebarPinned)}>
      <Sidebar pinned={sidebarPinned} togglePin={togglePin} />
      <Box width="100%" display="flex" flexDirection="column">
        <ClientHeader name={clientDetails?.client?.name || ''} isLoading={isClientLoading} />
        <Outlet context={{ clientName: clientDetails?.client?.name }} />
      </Box>
    </Box>
  );
};
