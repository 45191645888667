import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { Stack, Box, FormControlLabel } from '@mui/material';
import { joiResolver } from '@hookform/resolvers/joi';
import { useSnackbar } from 'notistack';
import { Radio, Tooltip, Typography } from 'lux/components';
import type { SubmitHandler } from 'react-hook-form';

import { useIntlForm } from 'hooks/useIntlForm/useIntlForm';
import { useProjectDetails } from 'hooks/useProjectDetails/useProjectDetails';
import { DialogInnerWrapper } from 'ui/dialogInnerWrapper/DialogInnerWrapper';
import { Checkbox } from 'ui/form/checkbox/Checkbox';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Select } from 'ui/form/select/Select';
import { mapToSelectOptions } from 'ui/form/select/Select.mappers';
import { seniorityList } from 'config/data/seniorityList';
import { convertFteToOptionValue, fteList, Fte } from 'config/data/fteList';
import { useUnsavedChangesDialogContext } from 'hooks/useUnsavedChangesDialogContext/useUnsavedChangesDialogContext';
import { Seniority } from 'api/types/Seniority.enum';
import { useEditAssignment } from 'hooks/useEditAssignment/useEditAssignment';
import { convertRateToInteger } from 'utils/convertRate';
import { isPM } from 'utils/isPM';
import { ProjectStage } from 'api/types/ProjectStage.enum';
import { AssignmentStatus } from 'api/types/AssignmentStatus.enum';
import { Switch } from 'ui/form/switch/Switch';
import { Translation } from 'ui/translation/Translation';
import type { Assignment } from 'api/actions/getPersonAssignments/getPersonAssignments.types';
import { getMaxAvailableAssignDate } from 'shared/assignments/Assignments.functions';
import { formatDate, isDateAfter, parseDate } from 'utils/dateUtils';
import { DatePicker } from 'ui/form/datePicker/DatePicker';
import { InfoBox } from 'ui/infoBox/InfoBox';
import { hourInputNumberParser, moneyInputNumberParser } from 'utils/createInputNumberChangeParser';

import { editAssignmentSchema } from './EditAssignment.schema';
import * as styles from './EditAssignmentContent.styles';
import { EditAssignmentSection } from './editAssignmentSection/EditAssignmentSection';
import type { EditAssignmentFormValues, EditAssignmentContentProps } from './EditAssignment.types';

const getDefaultValues = (data: Assignment): EditAssignmentFormValues => ({
  role: data.role,
  seniorityEnabled: data.seniority !== Seniority.withoutLevel,
  seniority: data.seniority === Seniority.withoutLevel ? '' : data.seniority,
  fte: convertFteToOptionValue(data.fte),
  startDate: parseDate(data.startDate),
  endDate: data.endDate ? parseDate(data.endDate) : null,
  customFte: convertFteToOptionValue(data.fte) === Fte.other ? String(data.fte) : '',
  customRateEnabled: data.isCustomRate,
  customRate: String(data.effectiveRateModifier),
  isTemporary: data.status === AssignmentStatus.temporary,
  // @todo: uncomment when assignment notes are implemented
  // notes: '',
});

export const EditAssignmentContent = ({ assignmentData, onClose }: EditAssignmentContentProps) => {
  const { formatMessage } = useLocale();
  const [currentRateCardRate, setCurrentRateCardRate] = useState<number | null>(null);
  const { projectDetails, employeeAssignments } = useProjectDetails();
  const { enqueueSnackbar } = useSnackbar();
  const { DialogClickAwayListenerWrapper } = useUnsavedChangesDialogContext();

  const { mutate, isLoading } = useEditAssignment(assignmentData.project.id, assignmentData.id);

  const { person } = assignmentData;

  const defaultValues = getDefaultValues(assignmentData);

  const form = useIntlForm<EditAssignmentFormValues>({
    mode: 'onSubmit',
    defaultValues,
    resolver: joiResolver(editAssignmentSchema(projectDetails.startDate)),
  });

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = form;

  const [seniorityEnabled, fte, customRateEnabled, role, seniority, startDate, endDate] = watch([
    'seniorityEnabled',
    'fte',
    'customRateEnabled',
    'role',
    'seniority',
    'startDate',
    'endDate',
  ]);

  const maxAvailableAssignDate = useMemo(() => {
    const { employeeId, employedTo } = person;
    return getMaxAvailableAssignDate(employeeAssignments, startDate, employeeId, employedTo);
  }, [person, startDate]);

  useEffect(() => {
    const rateCardItems = projectDetails.currentRateCard.items[role];
    const rate = rateCardItems?.find((item) => item.seniority === seniority)?.amount;

    setCurrentRateCardRate(rate || null);
  }, [role, seniority, projectDetails.currentRateCard]);

  const onSubmit: SubmitHandler<EditAssignmentFormValues> = (data) => {
    // @todo: uncomment notes when assignment notes are implemented
    const {
      role,
      seniority,
      seniorityEnabled,
      fte,
      customRateEnabled,
      customRate,
      customFte,
      isTemporary,
      startDate,
      endDate /*notes*/,
    } = data;

    const payload = {
      role,
      seniority: seniorityEnabled ? seniority : Seniority.withoutLevel,
      fte: fte === Fte.other ? customFte : fte,
      isTemporary,
      startDate: formatDate(startDate),
      endDate: endDate && formatDate(endDate),
      // notes,
      proposedRateInProject: {
        isCustomRate: customRateEnabled,
        proposedRate: convertRateToInteger(customRate),
      },
    };

    mutate(payload, {
      onSuccess: () => {
        enqueueSnackbar(
          formatMessage(
            { id: AppMessages['assignment.editAssignment.snackbar.success'] },
            {
              personName: `${person.firstName} ${person.lastName}`,
            },
          ),
        );
        onClose();
      },
    });
  };

  const isLastAssignedPM =
    isPM(assignmentData.role) &&
    assignmentData.project.assignedPmCount <= 1 &&
    assignmentData.project.stage === ProjectStage.ongoing;

  const availableSpecializations = Object.keys(projectDetails.currentRateCard.items);

  const isCurrentRoleWithoutLevel = projectDetails.currentRateCard.items[role]?.some(
    (value) => value.seniority === Seniority.withoutLevel,
  );

  const availableSeniorities = seniorityList().filter(
    (seniority) =>
      isCurrentRoleWithoutLevel ||
      projectDetails.currentRateCard.items[role]?.some((value) => value.seniority === seniority.value),
  );

  useEffect(() => {
    setValue('seniority', seniorityEnabled ? assignmentData.seniority : Seniority.withoutLevel, {
      shouldValidate: true,
    });

    if (!seniorityEnabled && !isCurrentRoleWithoutLevel) {
      setValue('customRateEnabled', true, { shouldValidate: true });
    }
  }, [seniorityEnabled, assignmentData.seniority]);

  useLayoutEffect(() => {
    setValue('seniorityEnabled', !isCurrentRoleWithoutLevel, { shouldValidate: true });
    setValue('seniority', isCurrentRoleWithoutLevel ? Seniority.withoutLevel : availableSeniorities[0].value, {
      shouldValidate: true,
    });
  }, [role, assignmentData.seniority]);

  const isSeniorityValid = seniorityEnabled
    ? availableSeniorities.some((s) => s.value === seniority)
    : seniority === Seniority.withoutLevel;

  const projectEndDate = assignmentData.project.endDate;
  const isNeedEndAfterProjectEnd = !!(endDate && projectEndDate) && isDateAfter(endDate, new Date(projectEndDate));

  return (
    <DialogClickAwayListenerWrapper defaultValues={defaultValues} form={form} onClose={onClose}>
      <DialogInnerWrapper
        title={formatMessage({ id: AppMessages['assignment.editAssignment.title'] })}
        onClose={onClose}
        isFormValid={isValid && availableSpecializations.includes(role) && isSeniorityValid}
        loading={isLoading}
        headerSx={styles.dialogHeader}
        contentSx={styles.dialogContent}
        submitText={formatMessage({ id: AppMessages['button.save'] })}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack gap={3}>
          <EditAssignmentSection title={<Translation id="assignment.editAssignment.form.sectionTitle.basics" />}>
            <Switch
              control={control}
              name="seniorityEnabled"
              label={formatMessage({ id: AppMessages['assignment.editAssignment.form.seniority'] })}
              size="medium"
              isDisabled
            />

            <Box sx={styles.seniorityRoleRow(seniorityEnabled)}>
              {seniorityEnabled && (
                <Select
                  name="seniority"
                  control={control}
                  label={formatMessage({ id: AppMessages['assignment.editAssignment.form.seniority'] })}
                  options={availableSeniorities}
                />
              )}

              <Tooltip
                placement="right"
                arrow
                title={
                  isLastAssignedPM
                    ? formatMessage({ id: AppMessages['assignment.editAssignment.form.error.noOtherPm'] })
                    : ''
                }
              >
                <div>
                  <Select
                    fullWidth
                    label={formatMessage({ id: AppMessages['assignment.editAssignment.form.role'] })}
                    control={control}
                    name="role"
                    options={mapToSelectOptions(availableSpecializations)}
                    isDisabled={isLastAssignedPM}
                  />
                </div>
              </Tooltip>
            </Box>

            <Box sx={styles.fteHoursRow(fte === Fte.other)}>
              <Select
                fullWidth
                name="fte"
                control={control}
                label={formatMessage({ id: AppMessages['assignment.editAssignment.form.fte'] })}
                options={fteList()}
              />

              {fte === Fte.other && (
                <FormTextField
                  type="number"
                  min={1}
                  max={40}
                  fullWidth
                  name="customFte"
                  control={control}
                  label={formatMessage({ id: AppMessages['assignment.editAssignment.form.customFte'] })}
                  onChangeParser={hourInputNumberParser}
                />
              )}
            </Box>
            <Stack spacing={3} direction="row" justifyContent="space-between" alignItems="flex-start">
              <DatePicker
                minDate={parseDate(assignmentData.project.startDate)}
                maxDate={endDate || undefined}
                fullWidth
                name="startDate"
                control={control}
                label={formatMessage({ id: AppMessages['assignment.editAssignment.form.startDate'] })}
              />
              <DatePicker
                minDate={startDate}
                maxDate={maxAvailableAssignDate}
                fullWidth
                name="endDate"
                control={control}
                label={formatMessage({ id: AppMessages['assignment.editAssignment.form.endDate'] })}
                disableUntilDate={startDate}
              />
            </Stack>
            {isNeedEndAfterProjectEnd && (
              <InfoBox
                variant="warning"
                title={formatMessage(
                  { id: AppMessages['assignment.editAssignment.form.warning.assignmentEndAfterProjectEnd'] },
                  { previousEndDate: projectEndDate, endDate: formatDate(endDate) },
                )}
              />
            )}
          </EditAssignmentSection>

          <EditAssignmentSection title={<Translation id="assignment.editAssignment.form.sectionTitle.rate" />}>
            <Box sx={styles.radioWrapper(!customRateEnabled)}>
              <FormControlLabel
                label={<Translation id="assignment.editAssignment.form.rate.fromRateCard" />}
                control={
                  <Radio
                    value="from-rate-card"
                    checked={!customRateEnabled}
                    disabled={!currentRateCardRate}
                    onClick={() => setValue('customRateEnabled', false)}
                  />
                }
              />
              <Typography sx={styles.currentRate}>
                <Translation
                  id="assignment.editAssignment.form.rate.current"
                  values={{ currentRate: `${currentRateCardRate ?? '-'} ${projectDetails.currency}` }}
                />
              </Typography>
            </Box>

            <Box sx={styles.radioWrapper(customRateEnabled)}>
              <FormControlLabel
                label={<Translation id="assignment.editAssignment.form.rate.custom" />}
                control={
                  <Radio
                    value="custom"
                    checked={customRateEnabled}
                    onClick={() => setValue('customRateEnabled', true)}
                  />
                }
              />
              <FormTextField
                size="small"
                name="customRate"
                startAdornment={assignmentData.project.currency}
                onChangeParser={moneyInputNumberParser}
                control={control}
                isDisabled={!customRateEnabled}
                label={formatMessage({ id: AppMessages['assignment.editAssignment.form.rate.custom'] })}
              />
            </Box>
          </EditAssignmentSection>

          <EditAssignmentSection title={<Translation id="assignment.editAssignment.form.sectionTitle.other" />}>
            <Box>
              <Checkbox
                control={control}
                name="isTemporary"
                label={formatMessage({ id: AppMessages['assignment.editAssignment.form.isTemporary'] })}
                size="medium"
              />
            </Box>

            {/* @todo show when assignment notes are implemented */}
            {/*<FormTextField*/}
            {/*  name="notes"*/}
            {/*  control={control}*/}
            {/*  label={formatMessage({ id: AppMessages['assignment.editAssignment.form.notes'] })}*/}
            {/*  fullWidth*/}
            {/*  rows={2}*/}
            {/*  multiline*/}
            {/*/>*/}
          </EditAssignmentSection>
        </Stack>
      </DialogInnerWrapper>
    </DialogClickAwayListenerWrapper>
  );
};
