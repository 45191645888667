import { ChangeEvent } from 'react';

import { Typography } from 'lux/components';
import { OpenFolderIcon } from 'lux/icons';
import { Stack } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Filter } from 'ui/filter/Filter';
import { useClientsFilters } from 'hooks/useClientsFilters/useClientsFilters';
import { ClientsFilters } from 'context/clientsFilters/clientsFiltersContext/ClientsFiltersContext.types';
import { DebouncedTextField } from 'ui/debouncedTextField/DebouncedTextField';
import { createNumberKeyDownHandler } from 'utils/createNumberKeyDownHandler';

import { ClientCountryFilterProps } from './ClientActiveProjectsFilter.types';

export const ClientActiveProjectsFilter = ({ variant }: ClientCountryFilterProps) => {
  const {
    filters: { activeProjectsFrom, activeProjectsTo },
    setFilterValue,
  } = useClientsFilters();
  const { formatMessage } = useLocale();

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: keyof ClientsFilters) => {
    const { value } = e.target;
    setFilterValue(name, value);
  };

  return (
    <Filter
      variant={variant}
      label={formatMessage({ id: AppMessages['clients.clientsList.filter.activeProjects'] })}
      shouldStretch
    >
      <Stack direction="row" alignItems="center" spacing={0.5} maxWidth={470} width="100%">
        <DebouncedTextField
          size="small"
          type="number"
          label={formatMessage({ id: AppMessages['clients.clientsList.filter.from'] })}
          fullWidth
          endAdornment={<OpenFolderIcon />}
          InputProps={{
            onKeyDown: createNumberKeyDownHandler({ allowNegative: false, decimalPrecision: 2 }),
            inputProps: {
              min: 0,
            },
          }}
          value={activeProjectsFrom}
          onChange={(e) => handleChange(e, 'activeProjectsFrom')}
        />
        <Typography color="text.secondary">-</Typography>
        <DebouncedTextField
          size="small"
          type="number"
          label={formatMessage({ id: AppMessages['clients.clientsList.filter.to'] })}
          fullWidth
          endAdornment={<OpenFolderIcon />}
          InputProps={{
            onKeyDown: createNumberKeyDownHandler({ allowNegative: false, decimalPrecision: 2 }),
          }}
          value={activeProjectsTo}
          onChange={(e) => handleChange(e, 'activeProjectsTo')}
        />
      </Stack>
    </Filter>
  );
};
