import { forwardRef } from 'react';

import { TableVirtuoso } from 'react-virtuoso';
import { Link } from 'react-router-dom';
import { Table } from 'lux/components';
import { Box } from '@mui/system';
import { ChevronRightIcon } from 'lux/icons';

import { SquareIconButton } from '../../../../ui/squareIconButton/SquareIconButton';

import { ClientsTableHead } from './clientsTableHead/ClientsTableHead';
import * as styles from './ClientsTable.styles';
import { ClientsTableProps } from './ClientsTable.types';

/*
    To meet the requirement that the whole table row should be a native link to client's profile
    we're using divs instead of <table>, <tr> etc. That's because using native HTML table elements,
    it not valid HTML to wrap an anchor (<a>) element around a table row (<tr>) element.
*/

export const ClientsTable = ({ clientsData, clientAction, onEndReached }: ClientsTableProps) => {
  const hasCustomPersonAction = Boolean(clientAction);

  return (
    <TableVirtuoso
      data={clientsData}
      fixedHeaderContent={ClientsTableHead}
      endReached={onEndReached}
      defaultItemHeight={53}
      increaseViewportBy={150}
      components={{
        FillerRow: (props) => (
          <Table.Row component="div">
            <Table.Cell component="div" style={{ height: props.height }} {...props} />
          </Table.Row>
        ),
        TableBody: forwardRef((props, ref) => (
          <Table.Body {...props} component="div" ref={ref} data-cy="clients-table_body" sx={styles.tableWrapper} />
        )),
        TableHead: forwardRef((props, ref) => <Table.Head component="div" {...props} ref={ref} />),
        Table: (props) => <Table.Table sx={styles.table} size="small" component="div" {...props} />,
        TableRow: ({ item: client, ...props }) => (
          <Table.Row
            hover
            {...props}
            sx={styles.tableRow(!hasCustomPersonAction)}
            key={client.id}
            component={hasCustomPersonAction ? 'div' : Link}
            to={`/clients/${client.id}`}
          />
        ),
      }}
      itemContent={(index, client) => (
        <>
          {/* <Table.Cell component="div">
            {hasCustomPersonAction ? (
              <Box component={Link} to={`/clients/${client.id}`} sx={styles.clientLink} target="_blank">
                <ClientBasicInfo name={client.name} clientId={client.id} />
              </Box>
            ) : (
              <ClientBasicInfo name={client.name} clientId={client.id} />
            )}
          </Table.Cell> */}
          <Table.Cell component="div">{client.name}</Table.Cell>
          <Table.Cell component="div">{client.country}</Table.Cell>
          <Table.Cell component="div">{client.industries.join(', ')}</Table.Cell>
          <Table.Cell component="div">{client.activeProjects}</Table.Cell>
          <Table.Cell padding="none" component="div">
            <Box data-cy="clients-table_client-action" className="hoverVisible">
              {clientAction ? (
                clientAction(client)
              ) : (
                <SquareIconButton disableRipple size="small">
                  <ChevronRightIcon />
                </SquareIconButton>
              )}
            </Box>
          </Table.Cell>
        </>
      )}
    />
  );
};
