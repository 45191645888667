import { getBillingSummary } from 'api/actions/getBillingSummary/getBillingSummary';
import { useAuth } from 'hooks/useAuth/useAuth';
import { useQuery } from 'hooks/useQuery/useQuery';
import { projectsKeys } from 'utils/queryKeys';

export const useBillingSummary = (projectId: string, date?: string, groupBy?: string, enabled = true) => {
  const { isAuthenticated } = useAuth();

  return useQuery(projectsKeys.projectBillingSummary(projectId, date, groupBy), getBillingSummary(projectId), {
    enabled: isAuthenticated && enabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false,
    cacheTime: 1_000_000,
    staleTime: 100_000,
    args: { date, groupBy },
  });
};
