import { Styles } from 'styles/theme';

export const wrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const actions: Styles = {
  display: 'inline-flex',
  alignItems: 'center',
  gap: 2,
};

export const monthsTextContainer: Styles = {
  textAlign: 'center',
  width: '170px',
};
