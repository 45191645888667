import { Box } from '@mui/material';
import { AddIcon } from 'lux/icons';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useTitle } from 'hooks/useTitle/useTitle';
import { AppMessages } from 'i18n/messages';
import { AppRoute } from 'routing/AppRoute.enum';
import { CardWrapper } from 'ui/cardWrapper/CardWrapper';
import { ConditionalWrapper } from 'ui/conditionalWrapper/ConditionalWrapper';
import { PageHeader } from 'ui/pageHeader/PageHeader';

import { ProjectsGrid } from './projectsGrid/ProjectsGrid';
import * as styles from './ProjectsList.styles';

export const Projects = () => {
  const { formatMessage } = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const isClientRoute = matchRoutes([{ path: AppRoute.clientDetails }], location) !== null;

  useTitle(formatMessage({ id: AppMessages['title.projects'] }));

  return (
    <ConditionalWrapper condition={isClientRoute} wrapper={(children) => <CardWrapper>{children}</CardWrapper>}>
      <Box sx={styles.container}>
        <PageHeader
          title={formatMessage({ id: AppMessages['projects.header.title'] })}
          actionButton={{
            actionTitle: formatMessage({ id: AppMessages['dashboard.header.actionBtn.text'] }),
            icon: <AddIcon />,
            onClick: () => navigate(AppRoute.createProject, { state: { from: AppRoute.projects } }),
          }}
        />
        <ProjectsGrid />
      </Box>
    </ConditionalWrapper>
  );
};
