import { Box, Button } from '@mui/material';

import { Loader } from 'ui/loader/Loader';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjects } from 'hooks/useProjects/useProjects';
import { useProjectsFilters } from 'hooks/useProjectsFilters/useProjectsFilters';
import { EmptyState } from 'ui/emptyState/EmptyState';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll/useInfiniteScroll';

import { ProjectCard } from './projectCard/ProjectCard';
import { ProjectsListSkeleton } from './ProjectsList.skeleton';
import * as styles from './ProjectsList.styles';

export const ProjectsList = () => {
  const { t } = useLocale();
  const { filters, resetAllFiltersToDefault } = useProjectsFilters();
  const { ref, queryMethods } = useInfiniteScroll(useProjects(filters));
  const { data, isLoading: isProjectsLoading, isFetchingNextPage } = queryMethods;

  const projects = data?.pages.flatMap((page) => page.projects);

  if (isProjectsLoading) {
    return <ProjectsListSkeleton />;
  }

  return (
    <>
      {!projects?.length ? (
        <EmptyState
          illustration="relaxPeople"
          title={t('projects.emptyProjects.title')}
          message={t('projects.emptyProjects.message')}
          actionBtn={<Button onClick={() => resetAllFiltersToDefault(['clientName'])}>{t('filters.reset')}</Button>}
        />
      ) : (
        <Box sx={styles.projectsListWrapper}>
          {projects.map((project, index) => (
            <ProjectCard key={project.id} ref={index === projects.length - 1 ? ref : null} project={project} />
          ))}
          {isFetchingNextPage && <Loader fullHeight={false} />}
        </Box>
      )}
    </>
  );
};
