import { Box } from '@mui/material';

import { Chips } from 'ui/chips/Chips';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';

import { ActiveFiltersProps } from './ActiveFilters.types';
import * as styles from './ActiveFilters.styles';

export const ActiveFilters = ({ dataCy, activeFiltersData, onFilterDelete }: ActiveFiltersProps) => {
  const { formatMessage } = useLocale();
  return (
    <>
      {activeFiltersData.length > 0 && (
        <Box data-cy="active-filter" sx={styles.container}>
          <Chips data-cy={dataCy}>
            {activeFiltersData.map((activeFilter, i) => {
              if (['activeProjectsFrom', 'activeProjectsTo'].includes(activeFilter.name)) {
                const message =
                  activeFilter.name === 'activeProjectsFrom'
                    ? AppMessages['clients.searchbar.ActiveProjectsFrom']
                    : AppMessages['clients.searchbar.ActiveProjectsTo'];

                return (
                  <Chips.Chip
                    key={`${activeFilter.value}.${i}`}
                    label={formatMessage(
                      {
                        id: message,
                      },
                      { projects: activeFilter.value },
                    )}
                    onDelete={activeFilter.isDeleteDisabled ? undefined : () => onFilterDelete(activeFilter)}
                  />
                );
              }
              return (
                <Chips.Chip
                  key={`${activeFilter.value}.${i}`}
                  label={activeFilter.label}
                  onDelete={activeFilter.isDeleteDisabled ? undefined : () => onFilterDelete(activeFilter)}
                />
              );
            })}
          </Chips>
        </Box>
      )}
    </>
  );
};
