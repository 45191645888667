import { useQueryClient } from '@tanstack/react-query';

import { AppMessages } from 'i18n/messages';
import { requestInvoiceCorrection } from 'api/actions/requestInvoiceCorrection/requestInvoiceCorrection';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { useLocale } from 'hooks/useLocale/useLocale';
import { clientsKeys } from 'utils/queryKeys';

export const useInvoiceCorrection = (invoiceId: string, clientId?: string) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();

  return useMutationDomainError(['requestInvoiceCorrection'], requestInvoiceCorrection(invoiceId), {
    onSuccess: () => {
      queryClient.refetchQueries(clientsKeys.clientInvoices(clientId));
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.requestInvoiceCorrection.error'] }),
  });
};
