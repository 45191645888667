import { useQueryClient } from '@tanstack/react-query';

import { AppMessages } from 'i18n/messages';
import { sendInvoice } from 'api/actions/sendInvoice/sendInvoice';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { useLocale } from 'hooks/useLocale/useLocale';
import { clientsKeys } from 'utils/queryKeys';
import { useClient } from 'hooks/useClient/useClient';

export const useSendInvoice = (invoiceId: string) => {
  const { clientDetails } = useClient();
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();

  return useMutationDomainError(['sendInvoice'], sendInvoice(invoiceId), {
    onSuccess: () => {
      queryClient.refetchQueries(clientsKeys.clientInvoices(clientDetails?.client.id));
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.sendInvoice.error'] }),
  });
};
