import { useAuth } from 'hooks/useAuth/useAuth';
import { clientsKeys } from 'utils/queryKeys';
import { GetClientsParams, GetClientsResponse } from 'api/actions/getClients/getClients.types';
import { parseSortFilter } from '../../utils/filters/filters';
import { useInfiniteQuery } from '../useInfiniteQuery/useInfiniteQuery';
import { getNextPageParam } from 'api/utils/getNextPageParam';
import { getInfiniteClientsQuery } from '../../api/actions/getClients/getClients';

import { UseClientsParams } from './useClients.types';

export const useClients = (params: UseClientsParams) => {
  const { isAuthenticated } = useAuth();
  const queryKey = [...clientsKeys.clients, params];

  let args: GetClientsParams = {};

  if (params?.search) {
    args = { ...args, name: params.search };
  }

  if (params?.country) {
    args = { ...args, country: params.country };
  }

  if (params?.industries) {
    args = { ...args, industries: params.industries };
  }

  if (params.activeProjectsFrom) {
    args = { ...args, activeProjectsFrom: params.activeProjectsFrom };
  }
  if (params.activeProjectsTo) {
    args = { ...args, activeProjectsTo: params.activeProjectsTo };
  }

  if (params?.sort) {
    const { field, order } = parseSortFilter(params.sort);

    args = {
      ...args,
      [`sort[${field}]`]: order,
    };
  }

  return useInfiniteQuery<GetClientsParams, GetClientsResponse>(queryKey, getInfiniteClientsQuery, {
    cursorKey: 'page',
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    startPage: 1,
    args: {
      ...args,
      limit: params.limit || 50,
    },
    getNextPageParam: getNextPageParam,
  });
};
