import { Filters } from 'hooks/useFilters/useFilters.types';

export enum ClientsSortingField {
  name = 'name',
  country = 'country',
  projectsCount = 'activeProjects',
  industries = 'industries',
}

export type ClientsFilters = {
  search: string;
  sort: string;
  country: string[];
  industries: string[];
  activeProjects: string;
  activeProjectsFrom: string;
  activeProjectsTo: string;
};

export type DefaultClientsFilters = Partial<ClientsFilters>;

export type ClientsFiltersValue = {
  filters: ClientsFilters;
} & Filters<ClientsFilters>;
