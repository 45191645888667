import { useQueryClient } from '@tanstack/react-query';

import { AppMessages } from 'i18n/messages';
import { payInvoice } from 'api/actions/payInvoice/payInvoice';
import { useMutationDomainError } from 'hooks/useMutationDomainError/useMutationDomainError';
import { useLocale } from 'hooks/useLocale/useLocale';
import { clientsKeys } from 'utils/queryKeys';
import { useClient } from 'hooks/useClient/useClient';

export const usePayInvoice = (invoiceId: string) => {
  const queryClient = useQueryClient();
  const { clientDetails } = useClient();

  const { formatMessage } = useLocale();

  return useMutationDomainError(['payInvoice'], payInvoice(invoiceId), {
    onSuccess: () => {
      queryClient.refetchQueries(clientsKeys.clientInvoices(clientDetails?.client.id));
    },
    fallbackErrorMessage: formatMessage({ id: AppMessages['snackbar.payInvoice.error'] }),
  });
};
