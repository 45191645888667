import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { ClientsFiltersContext } from '../clientsFiltersContext/ClientsFiltersContext';
import { ClientsSortingField } from '../clientsFiltersContext/ClientsFiltersContext.types';
import { useFilters } from 'hooks/useFilters/useFilters';
import { generateSortFilter } from 'utils/filters/filters';
import { filterEmptyQueryParams, stringifyQueryParams } from 'api/utils/queryString';
import { useOnRedirectHandler } from 'hooks/useOnRedirectHandler/useOnRedirectHandler';
import type { ClientsFilters } from '../clientsFiltersContext/ClientsFiltersContext.types';

import type { ClientsFiltersContextControllerProps } from './ClientsFiltersContextController.types';

export const clientsSortingOptions = generateSortFilter(ClientsSortingField);

const defaultClientsFilters: ClientsFilters = {
  search: '',
  sort: clientsSortingOptions.nameASC,
  industries: [],
  country: [],
  activeProjects: '',
  activeProjectsFrom: '1',
  activeProjectsTo: '',
};
export const ClientsFiltersContextController = ({
  children,
  withParams,
  clientsFilters,
  storageKey,
  storageType,
}: ClientsFiltersContextControllerProps) => {
  const [, setParamsFilters] = useSearchParams();

  const activeClientsFilters = {
    ...defaultClientsFilters,
    ...clientsFilters,
  };

  const contextValues = useFilters<ClientsFilters>(activeClientsFilters, { withParams, storageKey, storageType });

  useEffect(() => {
    if (withParams) {
      setParamsFilters(stringifyQueryParams(filterEmptyQueryParams(contextValues.filters, defaultClientsFilters)), {
        replace: true,
      });
    }
  }, []);

  useOnRedirectHandler(() => {
    contextValues.setStorageFiltersState({
      search: undefined,
    });
  });

  return <ClientsFiltersContext.Provider value={contextValues}>{children}</ClientsFiltersContext.Provider>;
};
