import { Styles } from 'styles/theme';

export const row: Styles = {
  textDecoration: 'none',

  '&& .MuiTableCell-root': {
    paddingY: 1,
  },
  '&& .MuiTableCell-body': {
    whiteSpace: 'wrap',
  },
};

export const statusInfo: Styles = {
  mt: 0.5,
};

export const buttonSkeleton: Styles = {
  ml: '1rem',
};

export const workstatementNumberSkeleton: Styles = {
  height: 66,
  width: 471,
};

export const actionsWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 1,
};

export const projectLabel: Styles = {
  '&.MuiTableCell-root.MuiTableCell-root': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 220,
  },
};
