import { Stack } from '@mui/material';
import { Button, InlineEdit } from 'lux/components';

import { invoiceDataActions } from 'api/actions/issueInvoice/issueInvoice.types';
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser';
import { useEditMode } from 'hooks/useEditMode/useEditMode';
import { useIssueInvoice } from 'hooks/useIssueInvoice/useIssueInvoice';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectBillingSummaryData } from 'hooks/useProjectBillingSummaryData/useProjectBillingSummaryData';
import { FormTextField } from 'ui/form/formTextEdit/FormTextField';
import { canEditInvoice } from 'utils/canEditInvoice';

import { AddInvoiceFormSubmitFn, IssueInvoiceActionProps } from './IssueInvoiceAction.types';
import { useIssueInvoiceForm } from './IssueInvoiceForm';

export const IssueInvoiceAction = ({
  invoiceNumber,
  invoiceData,
  invoiceDataAction,
  issueInvoiceMode,
}: IssueInvoiceActionProps) => {
  const { t } = useLocale();
  const { control, handleSubmit, clearErrors, formState } = useIssueInvoiceForm(
    invoiceNumber ? { invoiceNumber } : undefined,
  );
  const { resetInvoiceError } = useProjectBillingSummaryData();

  const { isLoading: isIssuingInvoice, mutate: issueInvoice } = useIssueInvoice(invoiceData ?? [], invoiceDataAction);
  const { data: user } = useCurrentUser();
  const isButtonDisabled = !canEditInvoice(user) || !invoiceData?.length;

  const handleClose = () => {
    setCloseEditMode();

    invoiceDataAction?.(invoiceDataActions.issueModeClose);
    invoiceDataAction?.(invoiceDataActions.clear);
    resetInvoiceError();

    control._formValues['invoiceNumber'] = invoiceNumber;
    clearErrors('invoiceNumber');
  };

  const handleIssueInvoice: AddInvoiceFormSubmitFn = (data) => {
    if (invoiceData) {
      issueInvoice({
        invoiceNumber: data.invoiceNumber,
        invoiceData: invoiceData.map((el) => ({
          projectId: el.projectId,
          billingCycleId: el.billingCycle.id,
          invoiceId: el.invoiceId,
        })),
        // TODO: For testing purposes now, to be handled later
        source: 'FINANCE',
      });
    }
  };

  const { inputRef, setOpenEditMode, setCloseEditMode } = useEditMode(handleIssueInvoice);

  const resetErrors = () => clearErrors('invoiceNumber');

  const handleClickIssueInvoice = () => {
    invoiceDataAction?.(invoiceDataActions.issueMode);
  };

  return (
    <Stack direction="row" gap={1}>
      {issueInvoiceMode ? (
        <InlineEdit
          header={t('projectDetails.invoices.addInvoice')}
          isActive={true}
          onDataDisplayClick={setOpenEditMode}
          onCancel={handleClose}
          onClickAway={() => {}}
          onSubmit={handleSubmit(handleIssueInvoice)}
          disabled={isButtonDisabled}
          submitDisabled={!!formState.errors.invoiceNumber}
          input={
            <FormTextField
              inputRef={inputRef}
              size="small"
              name="invoiceNumber"
              control={control}
              onFocus={resetErrors}
              label={t('projectDetails.invoices.addInvoice')}
            />
          }
        >
          -
        </InlineEdit>
      ) : (
        <>
          <Button
            type="submit"
            size="medium"
            loadingPosition="center"
            onClick={handleClickIssueInvoice}
            loading={isIssuingInvoice}
            disabled={isButtonDisabled}
          >
            {t('projectDetails.invoices.addInvoice')}
          </Button>
        </>
      )}
    </Stack>
  );
};
