import { useEffect } from 'react';

import { Divider } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { AppMessages, Translation } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectsFilters } from 'hooks/useProjectsFilters/useProjectsFilters';
import { Searchbar } from 'ui/searchbar/Searchbar';
import { ActiveFilter } from 'ui/activeFilters/ActiveFilters.types';
import { ActiveFilters } from 'ui/activeFilters/ActiveFilters';
import { filtersData } from 'config/data/projects/filters';
import { ProjectsFilters } from 'context/projectsFilters/projectsFiltersContext/ProjectsFiltersContext.types';
import { createActiveFilters } from 'ui/activeFilters/createActiveFilters';
import { useProjectsListConfig } from 'hooks/useProjectsListConfig/useProjectsListConfig';

import * as styles from './ProjectsListTopbar.styles';
import { SearchbarFormValues } from './ProjectsListTopbar.types';

const translationsBasedOnName: { name: string; labelTranslation: Translation }[] = [
  { name: 'search', labelTranslation: 'filters.search.value' },
];

export const ProjectsListTopbar = () => {
  const { formatMessage } = useLocale();
  const { filters, toggleFilter, setFilterValue } = useProjectsFilters();
  const { inputSearchLabel } = useProjectsListConfig();
  const { isCLientChipPreventedForDeletion } = useProjectsListConfig();

  const { control, handleSubmit, setValue, watch } = useForm<SearchbarFormValues>({
    defaultValues: {
      search: filters.search || '',
    },
  });

  const onSubmit: SubmitHandler<SearchbarFormValues> = (data) => {
    setFilterValue('search', data.search);
  };

  const handleClear = () => {
    setValue('search', '');
    setFilterValue('search', '');
  };

  const handleFilterDelete = ({ name, value }: ActiveFilter) => {
    if (isCLientChipPreventedForDeletion && name === 'clientName') {
      return;
    }
    toggleFilter(name as keyof ProjectsFilters, value);
  };

  useEffect(() => {
    setValue('search', filters.search || '');
  }, [filters.search]);

  const activeFilterData = createActiveFilters(filters, {
    exclude: ['sort'],
    translationsBasedOnValue: filtersData,
    translationsBasedOnName: translationsBasedOnName,
    disableDeletion: ['clientName'],
  });

  const inputLabel = inputSearchLabel ?? 'projects.searchbar.label';

  return (
    <>
      <Searchbar
        control={control}
        name="search"
        onSubmit={handleSubmit(onSubmit)}
        label={formatMessage({ id: AppMessages[inputLabel] })}
        isClearable={watch('search').length > 0}
        onClear={handleClear}
        focus={true}
      />
      <Divider sx={styles.divider} />
      <ActiveFilters activeFiltersData={activeFilterData} onFilterDelete={handleFilterDelete} />
    </>
  );
};
