import { forwardRef } from 'react';

import { Box, Divider, Stack } from '@mui/material';
import { Badge, Typography, Grid } from 'lux/components';
import { EndDateIcon, StartDateIcon } from 'lux/icons';
import { Link } from 'react-router-dom';

import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useProjectsFilters } from 'hooks/useProjectsFilters/useProjectsFilters';
import { ProjectStageBadge } from 'ui/projectStageBadge/ProjectStageBadge';
import { AppRoute } from 'routing/AppRoute.enum';

import { ProjectCardProps } from './ProjectCard.types';
import * as styles from './ProjectCard.styles';

export const ProjectCard = forwardRef<HTMLElement, ProjectCardProps>(({ project, showClientInfo = true }, ref) => {
  const { formatMessage } = useLocale();
  const { setFilterValue, filters } = useProjectsFilters();

  const searchByClientName = () => {
    const clientName = project.client.name;

    if (!filters.clientName.includes(clientName)) {
      setFilterValue('clientName', [clientName, ...filters.clientName]);
    }
  };

  return (
    <Box ref={ref} component={Link} to={`../projects/${project.id}`} sx={styles.projectCardWrapper}>
      <Stack sx={styles.wrapper}>
        {showClientInfo && (
          <Link to={`${AppRoute.clients}/${project.client.id}`} onClick={searchByClientName}>
            <Box width={'fit-content'}>
              <Typography>{project.client.name}</Typography>
              <Divider color="" />
            </Box>
          </Link>
        )}
        <Box sx={styles.topBar}>
          <Typography variant="h6">{project.name}</Typography>
          <ProjectStageBadge stage={project.stage} />
        </Box>
        <Grid
          className="hoverInvisible"
          container
          alignItems="center"
          spacing={0}
          columnSpacing={5}
          justifyContent="space-between"
          gutter={0}
        >
          <Grid item xs={!project.endDate}>
            <Typography sx={styles.dateWrapper} variant="body2">
              <StartDateIcon />
              {project.startDate}
            </Typography>
          </Grid>
          {project.endDate && (
            <Grid item xs>
              <Typography sx={styles.dateWrapper} variant="body2">
                <EndDateIcon />
                {project.endDate}
              </Typography>
            </Grid>
          )}

          <Grid item>
            <Badge
              isStatic
              color="gray"
              badgeContent={formatMessage(
                {
                  id: AppMessages['projects.projectCard.headCount'],
                },
                {
                  headCount: project.activeAssignments,
                },
              )}
            />
          </Grid>
        </Grid>
        <Stack direction="row" flexWrap="wrap" gap={0.5} marginTop={1} className="hoverInvisible">
          {project.tags.map((tag) => (
            <Badge isStatic color="gray" key={tag} badgeContent={tag} />
          ))}
        </Stack>
        <Box className="hoverVisible" position="absolute" bottom={0} left={0}>
          <Typography paragraph sx={styles.descriptionTitle} variant="caption" color="text.secondary">
            {formatMessage({ id: AppMessages['projects.projectCard.description.title'] })}
          </Typography>
          <Typography paragraph sx={styles.description} variant="caption">
            {project.description}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
});
